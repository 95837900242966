import React from "react";
import { Link as LinkS } from "react-scroll";
import styled from "styled-components";
import { useTheme } from "../Context/themeContext";
import Buttons from "./Buttons";

const MobileHeader = ({ isOpen, toggle }) => {
  const theme = useTheme();

  const handleEmail = () => {
    window.open("mailto:Info@mveledzorisk.co.za");
  };
  return (
    <MobileHeaderContainer isOpen={isOpen} onClick={toggle} theme={theme}>
      <Icon onClick={toggle}>
        <i className="fa fa-close" style={{ color: "#6152a2" }}></i>
      </Icon>
      <MobileHeaderWrapper>
        <MobileHeaderMenu>
          <MobileHeaderLink to="about" onClick={toggle}>
            About
          </MobileHeaderLink>
          <MobileHeaderLink to="services" onClick={toggle}>
            Services
          </MobileHeaderLink>
          <MobileHeaderLink to="ourTeam" onClick={toggle}>
            Our Team
          </MobileHeaderLink>
          <MobileHeaderLink to="contact" onClick={toggle}>
            Contact Us
          </MobileHeaderLink>
        </MobileHeaderMenu>
        <MobileHeaderBtnWrap onClick={handleEmail}>
          <Buttons
            solid2
            name={"Get Quote"}
            icon={"fas fa-chevron-right"}
            arrow={"arrow"}
            blob={"blob"}
          ></Buttons>
        </MobileHeaderBtnWrap>
      </MobileHeaderWrapper>
    </MobileHeaderContainer>
  );
};

export default MobileHeader;

const MobileHeaderContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colorTurquoise};
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;

const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

const MobileHeaderWrapper = styled.div`
  color: #fff;
`;

const MobileHeaderMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-row: repeat(6, 80px);
  text-align: center;
  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
  }
`;

const MobileHeaderLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: ${(props) => props.theme.colorTurquoise};
  cursor: pointer;
`;

const MobileHeaderBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;
