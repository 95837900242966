import React from "react";
import styled from "styled-components";
import { useTheme } from "../Context/themeContext";
import Button from "./Buttons";
import homeIns from "../Assets/homeIns.jpg";
import Agency from "../Assets/agency.jpg";
import { Link } from "react-scroll";

/*eslint-disable */

const Hero = () => {
  const theme = useTheme();
  return (
    <HeroStyled theme={theme}>
      <div className="headerContent u-pad-lg">
        <div className="leftHeaderContent">
          <h1>
            We make it <span>our business to understand</span> the ins and outs
            of your insurance needs.
          </h1>
          <br />
          <h3 id="about">About Us</h3>
          <p>
            Mveledzo Risk Solutions is an authorised Financial Services
            Providers (FSP Number: 52258), registered with the Financial Sector
            Conduct Authority (FSCA). We offer advisory and intermediary
            services, in terms of the Financial Advisory and Intermediary
            Services Act 2002. We provide personal and commercial insurance to
            protect your valuable assets from theft, loss and damage
          </p>
          <p>
            The professional team at Mveledzo Risk Solutions have in-depth
            understanding of the short-term insurance market. Our experience and
            proficiency in the industry ensures that we exceed our clients’
            service level expectations.
          </p>
          <p>
            Mveledzo Risk Solutions was founded in 2021. We are a 100% Black
            owned, BEE Level 1 registered company, based in Centurion, Pretoria
          </p>
          <LinkS
            to="ourVision"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-190}
            href="Our Vision"
          >
            <Button
              name={"Get Started"}
              icon={"fas fa-chevron-right"}
              arrow={"arrow"}
              blob={"blob"}
            />
          </LinkS>
        </div>
        <div className="rightHeaderContent">
          <img className="h-img-3" src={homeIns} alt="Home Insuarance" />
          <img className="h-img-2" src={Agency} alt="Insuarance Agency" />
        </div>
      </div>
      <div className="mouse">
        <span></span>
      </div>
    </HeroStyled>
  );
};

const HeroStyled = styled.header`
  min-height: 100vh;
  position: relative;

  .headerContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 2rem;
    padding: 5rem calc((100vw - 1300px) / 2);
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      padding-top: 2rem;
    }
    .leftHeaderContent {
      /* animation: contentHide 1s ease-in-out;
      @keyframes contentHide {
        0% {
          opacity: 0;
          transform: translateX(-120%) scaleX(0);
        }
        100% {
          opacity: 1;
          transform: translateX(0) scaleX(1);
        }
      } */
      @media only screen and (min-width: 912px) {
        padding-right: 1rem;
        padding-left: 1rem;
      }
      h1 {
        font-size: clamp(1.5rem, 6vw, 4rem);
        font-weight: 800;

        span {
          background: ${(props) => props.theme.colorGradientPrimary2};
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
        }
      }
      p {
        font-size: clamp(1rem, 3vw, 1.2rem);
        line-height: 1.6rem;
        padding: 1rem 0;
      }
      h3,
      p,
      h1 {
        @media screen and (max-width: 768px) {
          padding-right: 1rem;
          padding-left: 1rem;
        }
      }
    }
    .rightHeaderContent {
      position: relative;
      /* transition: all 0.4s ease-in-out; */
      /* animation: contentHide2 1s ease-in-out; */
      /* animation-delay: 0.5s; */
      opacity: 1;
      /* animation-fill-mode: forwards; */

      /* @keyframes contentHide2 {
        0% {
          opacity: 0;
          transform: translateY(-120%) scale(0);
          visibility: none;
        }
        100% {
          opacity: 1;
          transform: translateY(0) scale(1);
          visibility: visible;
        }
      } */

      &:hover {
        .h-img-2 {
          transform: scale(0.9);
        }
        .h-img-3 {
          transform: scale(1.1);
        }
      }
      .h-img-2 {
        position: absolute;
        width: 70%;
        height: 60%;
        object-fit: cover;
        top: 15%;
        left: 5%;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 70px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        transition: all 0.4s ease-in-out;
      }
      .h-img-3 {
        position: absolute;
        width: 70%;
        height: 80%;
        object-fit: cover;
        right: 0;
        border-bottom-right-radius: 70px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        transition: all 0.4s ease-in-out;
      }
      .h-img-3,
      .h-img-2 {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      .h-img-3,
      .h-img-2 {
        @media only screen and (min-width: 820px) and (max-width: 912px) {
          height: 40%;
          width: 80%;
          position: relative;
        }
      }
    }
  }
  .mouse {
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50);
    width: 30px;
    height: 45px;
    border-radius: 30px;
    border: 2px solid ${(props) => props.theme.colorBlack};
    pointer-events: none;
    span {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 6px;
      height: 6px;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background: ${(props) => props.theme.colorGradientPrimary3};
      animation: upDown 1s infinite;

      @keyframes upDown {
        0% {
          transform: translateY(-13px) translateX(-50%) scale(1.3);
        }
        10% {
          transform: translateY(-13px) translateX(-50%) scale(1.3);
        }
        50% {
          transform: translateY(10px) translateX(-50%) scale(1);
        }
        100% {
          transform: translateY(-13px) translateX(-50%) scale(1);
        }
      }
    }
  }
`;
const LinkS = styled(Link)`
  padding-right: 1rem;
  padding-left: 1rem;
`;

export default Hero;
