import React from "react";
import styled from "styled-components";
import { useTheme } from "../Context/themeContext";

const Title = ({ name, subtext }) => {
  const theme = useTheme();
  return (
    <TitleStyled theme={theme}>
      <h3>{name}</h3>
      <p>{subtext}</p>
    </TitleStyled>
  );
};

const TitleStyled = styled.div`
  h3 {
    font-size: 2.5rem;
    font-weight: 700;
    position: relative;
    padding-bottom: 1rem;
    &::after {
      content: "";
      left: 33%;
      position: absolute;
      bottom: 0;
      width: 30%;
      height: 2px;
      background-color: ${(props) => props.theme.colorAccent2};
    }
  }
`;
export default Title;
