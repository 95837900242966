import React from "react";
import styled from "styled-components";
import { useTheme } from "../Context/themeContext";
import OurTeamCard from "./OurTeamCard";
import Title from "./Title";
import enos from "../Assets/enos.jpeg";
import cleo from "../Assets/cleo.jpeg";

const OurTeam = () => {
  const theme = useTheme();
  return (
    <OurTeamStyled theme={theme} id="ourTeam">
      <div className="titleContainer">
        <Title
          name={"Meet Our Team"}
          subtext={"Discover our Insurance leaders and specialists!"}
        />
      </div>
      <div className="ourTeamCards">
        <OurTeamCard
          img={enos}
          name={"ENOS NDHLOVU (AIISA), (CIMA Dip MA)"}
          about={
            "Mr Ndhlovu is a commercially experienced executive with extensive knowledge and experience in insurance and data & analytics. He holds a MBA from GIBS. Mr Ndhlovu is a qualified broker who was Managing Director of one of the biggest Pet Insurance companies in South Africa."
          }
        />
        <OurTeamCard
          img={cleo}
          name={"CLEO NDHLOVU"}
          about={
            "Ms Ndhlovu has experience in operations management and quality management systems (QMS). She holds a MSc from the University of Barcelona and she is a certified (IRCA) lead auditor. In 2017 she was runner-up in the Standard Bank Businesswoman of the Year Awards"
          }
        />
      </div>
    </OurTeamStyled>
  );
};

const OurTeamStyled = styled.section`
  @media screen and (max-width: 768px) {
    margin-top: -3rem;
  }
  .ourTeamCards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
    margin-left: 18rem;
    margin-right: 18rem;
    margin-bottom: 6rem;
    @media screen and (max-width: 912px) {
      grid-template-columns: 1fr 1fr;
      margin-left: 2rem;
      margin-right: 2rem;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
`;

export default OurTeam;
