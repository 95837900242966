import React from "react";
import styled from "styled-components";
import { useTheme } from "../Context/themeContext";
import logo from "../Assets/mvel.jpg";
const FooterSection = () => {
  const theme = useTheme();
  return (
    <FooterSectionStyled theme={theme} id="contact">
      <nav className="bottomNav">
        <ul className="logoContainer">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <p>
            We make it our business to understand the ins and outs of your
            insurance needs.
          </p>
        </ul>
        <ul className="navBottom navBottomContact">
          <h4>Contact Us</h4>
          <li className="navBottomItem">
            <div className="icon">
              <i className="fas fa-envelope"></i>
            </div>
            <div className="f-text">
              <h6>Email</h6>
              <span>Info@mveledzorisk.co.za</span>
            </div>
          </li>
          <li className="navBottomItem">
            <div className="icon">
              <i className="fas fa-phone-alt"></i>
            </div>
            <div className="f-text">
              <h6>Phone</h6>
              <span>082 5297 196</span>
            </div>
          </li>
          <li className="navBottomItem">
            <div className="icon">
              <i className="fas fa-map-marker-alt"></i>
            </div>
            <div className="f-text">
              <h6>Location</h6>
              <span>Unit 2 Administrative Building</span>
              <br />
              <span>72 Beauly Avenue</span>
              <br />
              <span>Kosmosdal Extension 19</span>
              <br />
              <span>Blue Valley Golf & Country Estate.</span>
              <br />
              <span>Centurion</span>
            </div>
          </li>
        </ul>
      </nav>
      <div className="footerCopywrite">
        <p>
          © Copyright {new Date().getFullYear()}
          <span>. Mveledzo Risk Solutions. </span> All rights reserved
        </p>
        <p>
          Developed by -{" "}
          <a href="https://thamsanqaj-fea43.web.app/" rel="noopener">
            Thamsanqa J
          </a>
        </p>
      </div>
    </FooterSectionStyled>
  );
};

const FooterSectionStyled = styled.footer`
  background: ${(props) => props.theme.colorBg3};
  padding-top: 6rem !important;
  padding-bottom: 3rem !important;
  h6 {
    font-size: 1.2rem;
  }
  h4 {
    font-size: 1.5rem;
    color: ${(props) => props.theme.colorWhite};
  }
  i {
    font-size: 1.3rem;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: ${(props) => props.theme.colorAcent};
    }
  }

  img {
    margin-bottom: 1rem;
    border-radius: 10px;
  }

  .bottomNav {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 3rem;
    h4 {
      padding-bottom: 1.5rem;
    }
    .navBottom {
      margin: 0 1.5rem;
      .navBottomItem {
        margin-bottom: 1rem;
        color: ${(props) => props.theme.colorWhite};
      }
    }
    .navBottomContact {
      .navBottomItem {
        display: grid;
        grid-template-columns: 30px auto;
      }
    }
    .logoContainer {
      flex: 5;
      margin-left: 30px;
      p {
        color: ${(props) => props.theme.colorWhite};
      }
    }
  }
  .footerCopywrite {
    padding-top: 3rem;
    border-top: 1px solid ${(props) => props.theme.colorGrey9};
    text-align: center;
    span {
      color: ${(props) => props.theme.colorWhite};
      cursor: pointer;
    }
    p {
      color: ${(props) => props.theme.colorWhite};
    }
    a {
      font-weight: 200 !important;
    }
  }
`;
export default FooterSection;
