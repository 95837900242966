import React from "react";
import styled from "styled-components";
import { useTheme } from "../Context/themeContext";
import whyUs from "../Assets/whyUs.jpg";
import Buttons from "./Buttons";
import { Link } from "react-scroll";

const WhyUs = () => {
  const theme = useTheme();
  return (
    <div style={{ backgroundColor: "white" }}>
      <WhyUsStyled theme={theme}>
        <div className="leftSide">
          <img src={whyUs} alt="Why Us" />
        </div>
        <div className="rightSide">
          <div className="rightSideContent">
            <h3 className="title">Why Choose Us?</h3>
            <div className="whyUs">
              <i
                className="fa fa-check-circle"
                style={{ color: "#49c2c6" }}
              ></i>
              <p>
                Every aspect of Mveledzo Risk Solutions revolves around our
                clients’ unique needs and preferences. We provide services that
                create added value by promoting quality focused customer
                collaboration. We listen to you, analyse your needs and
                consistently deliver exceptional service. We save you time,
                energy and money.
              </p>
            </div>
            <div className="whyUs">
              <i
                className="fa fa-check-circle"
                style={{ color: "#49c2c6" }}
              ></i>
              <p>
                We make it our business to know all the terms and conditions of
                our clients’ policy. We make sure that you are adequately
                covered, therefore giving you a peace of mind.{" "}
              </p>
            </div>
            <div className="whyUs">
              <i
                className="fa fa-check-circle"
                style={{ color: "#49c2c6" }}
              ></i>
              <p>
                We have access to South Africa’s top insurers making sure that
                you have the best insurance options at the lowest possible
                premium.
              </p>
            </div>
            <div className="whyUs">
              <i
                className="fa fa-check-circle"
                style={{ color: "#49c2c6" }}
              ></i>
              <p>
                Our state-of-the art technology and efficient back-office
                management optimisation systems keeps us ahead of the
                competition and on top of our clients’ business.
              </p>
            </div>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              href="#"
            >
              <Buttons
                name={"Contact Us Now!"}
                icon={"fas fa-chevron-right"}
                arrow={"arrow"}
                blob={"blob"}
              />
            </Link>
          </div>
        </div>
      </WhyUsStyled>
    </div>
  );
};

const WhyUsStyled = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin-bottom: 6rem;
  background-color: ${(props) => props.theme.colorWhite};
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .leftSide {
    overflow: hidden;
    border-top-left-radius: 5px;
    img {
      border-top-left-radius: 5px;
      width: 100%;
      object-fit: cover;
      filter: grayscale(100%);
      height: 100%;
      transition: all 0.3s ease-in-out;
      &:hover {
        filter: grayscale(0);
        transform: scale(1.2) rotate(-5deg);
      }
    }
    img {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
  .rightSide {
    display: flex;
    align-items: center;
    .rightSideContent {
      padding: 1rem;
      h3 {
        font-size: 2.5rem;
        padding-bottom: 1rem;
        position: relative;
        margin-bottom: 2rem;
        &::after {
          content: "";
          left: 0;
          position: absolute;
          bottom: 0;
          width: 20%;
          height: 2px;
          background-color: ${(props) => props.theme.colorAccent};
        }
      }
      p {
        padding-bottom: 2rem;
      }
      .whyUs {
        display: flex;
        /* align-items: center; */
        i {
          padding-right: 0.5rem;
        }
      }
    }
  }
`;
export default WhyUs;
