import React from "react";
import styled from "styled-components";
import { useTheme } from "../Context/themeContext";
import ourVision from "../Assets/ourVision.svg";
import ourMission from "../Assets/ourMission.svg";
import ourValues from "../Assets/ourValues.svg";
import about1 from "../Assets/about1.jpg";

const AboutSection = () => {
  const theme = useTheme();
  return (
    <div style={{ backgroundColor: "white" }}>
      <AboutSectionStyled
        // className="u-pad-lg-2"
        theme={theme}
        id="ourVision"
      >
        <div className="leftAbout">
          {/* <h3 className="title">About Us</h3>
        <p>
          The professional team at Mveledzo Risk Solutions have in-depth
          understanding of the short-term insurance market. Our experience and
          proficiency in the industry ensures that we exceed our clients’
          service level expectations. The company was founded in 2021. We are a
          100% Black owned, BEE Level 1 registered company, based in Centurion,
          Pretoria.
        </p> */}
          <div className="aboutInfo">
            <div className="aboutInfoItem">
              <div className="iconWrapper">
                <img src={ourVision} alt="Our Vision" className="icon" />
              </div>
              <div className="textContainer">
                <h5>Our Vision</h5>
                <p>
                  Our vision is to be recognised as a professional personal and
                  commercial insurance provider. To explore opportunities,
                  constantly and consistently, for the improvement and
                  advancement for the protection of your and our business.
                </p>
              </div>
            </div>
            <div className="aboutInfoItem">
              <div className="iconWrapper">
                <img src={ourMission} alt="Our Mission" className="icon" />
              </div>
              <div className="textContainer">
                <h5>Our Mission</h5>
                <p>
                  Our mission is to be the broker of choice that delivers
                  superior customer service making us a dominant player in the
                  insurance industry. To be customer centric and to practise the
                  highest business principles and ethics.
                </p>
              </div>
            </div>
            <div className="aboutInfoItem">
              <div className="iconWrapper">
                <img src={ourValues} alt="Our Mission" className="icon" />
              </div>
              <div className="textContainer">
                <h5>Our Values</h5>
                <div className="ourValues">
                  <i
                    className="fa fa-check-circle"
                    style={{ color: "#6152a2" }}
                  ></i>
                  <p>
                    We inspire growth and innovation through learning and bold
                    action.
                  </p>
                </div>
                <div className="ourValues">
                  <i
                    className="fa fa-check-circle"
                    style={{ color: "#6152a2" }}
                  ></i>
                  <p>
                    We have an uncompromising focus on treating all our
                    stakeholders with respect while delivering excellence.
                  </p>
                </div>
                <div className="ourValues">
                  <i
                    className="fa fa-check-circle"
                    style={{ color: "#6152a2" }}
                  ></i>
                  <p>
                    {" "}
                    We act with honesty, integrity, and thoughtfulness and take
                    responsibility for our decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rightAbout">
          <img src={about1} alt="" className="aboutImg" />
        </div>
      </AboutSectionStyled>
    </div>
  );
};

const AboutSectionStyled = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  padding-top: 3rem !important;
  padding-bottom: 4rem !important;
  margin-left: 10rem;
  margin-right: 10rem;

  @media only screen and (min-width: 820px) and (max-width: 912px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    grid-template-columns: 1fr;

    .aboutImg {
      display: none;
    }
  }

  .leftAbout {
    @media screen and (max-width: 768px) {
      margin-top: -3rem;
      margin-bottom: -3rem;
    }
    h3 {
      position: relative;
      padding-bottom: 1rem;
      &::after {
        content: "";
        left: 0;
        position: absolute;
        bottom: 0;
        width: 20%;
        height: 2%;
        background-color: ${(props) => props.theme.colorAccent};
      }
    }
    p {
      padding: 1.5rem 0;
    }
    .aboutInfo {
      .aboutInfoItem {
        display: grid;
        grid-template-columns: 10rem auto;
        margin-bottom: 1.5rem;
        p {
          padding: 0.6rem 0;
        }
        .title {
          font-size: 1.9rem;
        }
        h5 {
          font-size: 1.3rem;
        }
        .iconWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: flex-start;
          margin-right: 3rem;
          padding: 0.1rem;
        }
        .icon {
          height: 6rem;
          width: 6rem;
        }
      }
    }
    .ourValues {
      display: flex;
      /* align-items: center; */
      i {
        padding-top: 0.6rem;
        padding-right: 0.5rem;
      }
      /* flex-direction: column; */
    }
  }
  .rightAbout {
    position: relative;
    display: flex;
    justify-content: flex-end;
    .aboutImg {
      width: 90%;
      height: 70%;
      object-fit: cover;
      align-self: center;
      border-top-left-radius: 70px;
      border-bottom-right-radius: 70px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      transition: all 0.3s ease-in-out;
    }
    @media only screen and (min-width: 820px) and (max-width: 912px) {
      width: 100%;
      height: 80%;
      align-self: center;
    }
  }
`;

export default AboutSection;
