import React from "react";
import styled from "styled-components";
import { useTheme } from "../Context/themeContext";

/*eslint-disable */
const ServicesCard = ({ title, desc, icon }) => {
  const theme = useTheme();
  return (
    <ServiceCardStyled theme={theme}>
      <div className="servicesItemContainer">
        <img src={icon} alt="" />
        <h4>{title}</h4>
        <p>{desc}</p>
      </div>
    </ServiceCardStyled>
  );
};

const ServiceCardStyled = styled.section`
  background-color: ${(props) => props.theme.colorGreyLight};
  text-align: center;
  padding: 3rem 1rem;
  border-radius: 7px;
  transition: all 0.4s ease-in-out;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background: ${(props) => props.theme.colorGradientPrimary2};
    transition: all 0.4s ease-in-out;
    transform: scale(0);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &:hover {
    box-shadow: 10px 10px 0px ${(props) => props.theme.colorBorder};
    transform: translateY(-5px);
    &::before {
      transform: scale(1);
    }
  }
  .servicesItemContainer {
    img {
      padding-bottom: 2rem;
      height: 150px;
    }
    h4 {
      font-size: 1.5rem;
      padding-bottom: 0.5rem;
      color: ${(props) => props.theme.colorTurquoise};
    }
    p {
      color: white;
      padding-bottom: 1.2rem;
    }
    button {
      color: ${(props) => props.theme.colorTurquoise};
      background: none;
      border: none;
      padding: 0;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
`;
export default ServicesCard;
