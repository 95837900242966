import React, { useState } from "react";
import styled from "styled-components";
import { useTheme } from "../Context/themeContext";
import useCollapse from "react-collapsed";
/*eslint-disable */
const ServicesCard = ({ product }) => {
  const theme = useTheme();
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <ServicesCardStyled theme={theme}>
      <div className="servicesItemContainer">
        <img src={product.icon} alt="" />
        <h4>{product.title}</h4>
        <p>{product.desc}</p>
        <ul>
          {product.products.map((item, index) => {
            return (
              <li key={index} {...getCollapseProps()}>
                <div className="products">
                  <i
                    className="fa fa-check-circle"
                    style={{ color: "#6152a2" }}
                  ></i>
                  <p>{item}</p>
                </div>
              </li>
            );
          })}
        </ul>

        <button
          {...getToggleProps({
            onClick: () => setExpanded((prevExpanded) => !prevExpanded),
          })}
        >
          <span> {isExpanded ? "Less Info" : "More Info"}</span>
          <span className="arrow">
            <i className="fas fa-chevron-right"></i>
          </span>
        </button>
      </div>
    </ServicesCardStyled>
  );
};

const ServicesCardStyled = styled.section`
  height: fit-content;
  background-color: ${(props) => props.theme.colorGreyLight};
  text-align: center;
  padding: 3rem 1rem;
  border-radius: 7px;
  transition: all 0.4s ease-in-out;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background: ${(props) => props.theme.colorGradientPrimary2};
    transition: all 0.4s ease-in-out;
    transform: scale(0);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &:hover {
    box-shadow: 10px 10px 0px ${(props) => props.theme.colorBorder};
    transform: translateY(-5px);
    &::before {
      transform: scale(1);
    }
  }

  .servicesItemContainer {
    img {
      padding-bottom: 2rem;
      height: 150px;
    }
    h4 {
      font-size: 1.5rem;
      padding-bottom: 0.5rem;
      color: ${(props) => props.theme.colorTurquoise};
    }
    p {
      color: white;
      padding-bottom: 1.2rem;
    }
    button {
      color: ${(props) => props.theme.colorTurquoise};
      background: none;
      border: none;
      padding: 0;
      font-size: 1.2rem;
      font-weight: 600;
    }

    .products {
      display: flex;
      color: white;
      text-align: left;
      padding-left: 4rem;

      i {
        padding-right: 0.5rem;
      }
    }
  }
`;

export default ServicesCard;
