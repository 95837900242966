import React from "react";
import styled from "styled-components";
import { useTheme } from "../Context/themeContext";

const Buttons = ({ name, icon, arrow, blob, solid, solid2 }) => {
  const theme = useTheme();
  return (
    <ButtonStyled theme={theme} solid={solid} solid2={solid2}>
      {name}
      <span className={arrow}>
        <i className={icon}></i>
      </span>
      <span className={blob}></span>
    </ButtonStyled>
  );
};

const ButtonStyled = styled.a`
  cursor: pointer;
  overflow: hidden;
  background: ${(props) =>
    props.solid
      ? props.theme.colorTurquoise
      : props.solid2
      ? props.theme.colorPurple
      : props.theme.colorGradientPrimary3};
  padding: 0.8rem 2rem;
  border-radius: 30px;
  position: relative;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  color: ${(props) => props.theme.colorBlack} !important;
  .blob {
    position: absolute;
    top: -5px;
    right: -55%;
    height: 180px;
    width: 180px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    transition: all 0.4s cubic-bezier(0.21, 0.69, 0.73, 1.08);
  }
  &:hover {
    span {
      right: -30%;
      top: -15px;
    }
  }
  .solid {
    background: ${(props) => props.theme.colorTurquoise};
  }
`;

export default Buttons;
