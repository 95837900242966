import React from "react";
import styled from "styled-components";
import { useTheme } from "../Context/themeContext";

const OurTeamCard = ({ img, name, about }) => {
  const theme = useTheme();

  return (
    <OurTeamCardStyled theme={theme}>
      <div className="ourTeamCard">
        <div className="ourTeamCardImg">
          <img src={img} alt="" />
        </div>

        <div className="ourTeamCardContent">
          <h4 className="name">{name}</h4>
          <br />
          <p className="about">{about}</p>
        </div>
      </div>
    </OurTeamCardStyled>
  );
};

const OurTeamCardStyled = styled.section`
  h1 {
    text-align: center;
  }
  p {
    text-align: center;
    @media screen and (max-width: 768px) {
      font-size: 17px;
    }
  }
  .ourTeamCard {
    background-color: #ffffff;
    margin: 50px auto;
    width: 90%;
    height: 90%;
    text-align: center;
    border-radius: 10px;
    box-shadow: 10px 10px 30px #bdbdbd;
    padding-bottom: 20px;
    @media screen and (max-width: 768px) {
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      width: 100%;
    }
    .ourTeamCardImg {
      padding-top: 15px;
    }
    img {
      border-radius: 10px 10px 0 0;
      overflow: hidden;
      height: 70%;
      width: 70%;

      @media screen and (max-width: 768px) {
        width: 70%;
        height: 70%;
      }
    }
  }
  .ourTeamCardContent {
    .about,
    .title,
    .name {
      padding: 5px;
    }
    .about {
      padding: 0 1rem;
      /* width: 100%;
      height: 100%; */

      @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
        /* height: 10px; */
      }
    }
  }
`;

export default OurTeamCard;
