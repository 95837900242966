import React from "react";
import styled from "styled-components";
import { useTheme } from "../Context/themeContext";
import logo from "../Assets/logo.jpg";
import { Link } from "react-scroll";
import { animateScroll as scroll } from "react-scroll";
import Button from "./Buttons";

const Header = ({ toggle }) => {
  const theme = useTheme();

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const handleEmail = () => {
    window.open("mailto:Info@mveledzorisk.co.za");
  };
  return (
    <HeaderStyled theme={theme}>
      <nav style={{ backgroundColor: "#49c2c6", padding: "1.5rem" }}>
        <p style={{ fontWeight: "bold" }}>
          Authorised Financial Services Provider: FSP Number: 52258
        </p>
      </nav>
      <nav className="navigation1">
        <div className="logo">
          <Link onClick={toggleHome}>
            <img src={logo} alt="Logo" />{" "}
          </Link>
        </div>
        <ul className="nav1Items">
          <li className="nav1Item">
            <div className="icon">
              <i className="fas fa-phone-alt"></i>
            </div>
            <div className="f-text">
              <h6>CALL US TODAY</h6>
              <span>082 5297 196</span>
            </div>
          </li>
          <li className="nav1Item">
            <div className="icon">
              <i className="fas fa-clock"></i>
            </div>
            <div className="f-text">
              <h6>WE ARE OPEN</h6>
              <span>Mon-Fri 8:00-17:00</span>
            </div>
          </li>
        </ul>
        <div className="mobileIcon">
          <i className="fa-solid fa-bars" onClick={toggle}></i>
        </div>
      </nav>
      <nav className="navigation">
        <ul className="navItems">
          <li className="navItem">
            <Link
              to="about"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-240}
              href="about"
            >
              About Us
            </Link>
          </li>
          <li className="navItem">
            <Link
              to="services"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-240}
              href="services"
            >
              Our Services
            </Link>
          </li>
          <li className="navItem">
            <Link
              to="ourTeam"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-240}
              href="ourTeam"
            >
              Our Team
            </Link>
          </li>
          <li className="navItem">
            <Link
              to="contact"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-60}
              href="contact"
            >
              Contact Us
            </Link>
          </li>
        </ul>
        <div className="quoteBtn" onClick={handleEmail}>
          <Button
            solid
            name={"Get Quote"}
            icon={"fas fa-chevron-right"}
            arrow={"arrow"}
            blob={"blob"}
          />
        </div>
      </nav>
    </HeaderStyled>
  );
};

const HeaderStyled = styled.header`
  position: sticky;
  top: 0;
  z-index: 10;
  .navigation1 {
    display: flex;
    padding: 0.4rem 2rem;
    background-color: ${(props) => props.theme.colorWhite};

    .mobileIcon {
      display: none;
    }
    @media screen and (max-width: 768px) {
      .mobileIcon {
        display: block;
        position: absolute;
        top: 4.5rem;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 2rem;
        cursor: pointer;
        color: ${(props) => props.theme.colorPurple};
      }
    }
    .logo {
      flex: 2;
      align-items: center;

      img {
        width: auto;
        height: 60px;
      }
    }
    .nav1Items {
      display: flex;
      /* align-items: center; */
      /* justify-content: flex-start; */
      /* margin-left: 5rem; */
      @media screen and (max-width: 768px) {
        display: none;
      }
      .nav1Item {
        margin: 0 0.7rem;
        display: flex;
        align-items: center;
        padding-left: 0.5rem;
        .icon {
          font-size: 2rem;
          padding: 0 0.6rem;
        }
        h6 {
          color: ${(props) => props.theme.colorGreyLight};
        }
        span {
          font-size: medium;
        }
        Link {
          text-transform: uppercase;
          font-size: 1rem;
          padding: 0.8rem;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: ${(props) => props.theme.colorGradientPrimary2};
            border-radius: 10px;
            transform: scale(0);
            transition: all 0.4s ease-in-out;
            z-index: -1;
          }
          &:hover {
            &::before {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
  .navigation {
    display: flex;
    padding: 1rem 2rem;
    border-bottom: 1px solid ${(props) => props.theme.colorGrey9};
    background-color: ${(props) => props.theme.colorPurple};

    @media screen and (max-width: 768px) {
      display: none;
    }

    .navItems {
      display: flex;
      flex: 2;
      align-items: center;
      justify-content: flex-start;
      margin-left: 5rem;
      @media screen and (max-width: 768px) {
        display: none;
      }
      .navItem {
        margin: 0 0.7rem;
        Link {
          text-transform: uppercase;
          font-size: 1rem;
          padding: 0.8rem;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: ${(props) => props.theme.colorGradientPrimary2};
            border-radius: 10px;
            transform: scale(0);
            transition: all 0.4s ease-in-out;
            z-index: -1;
          }
          &:hover {
            &::before {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
`;

export default Header;
