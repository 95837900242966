const themes = [
  {
    name: "Default",
    colorPrimary: "#554DDE",
    colorAccent: "#6152a2",
    colorAccent2: "#49c2c6",
    colorSecondaryDark: "#16194F",
    colorBg: "#F1F1F1",
    colorBg2: "#10121A",
    colorBg3: "linear-gradient(43deg, #58595b 0%, #6152a2 46%, #49c2c6 100%)",
    colorTurquoise: "#49c2c6",
    colorPurple: "#6152a2",
    colorGreyLight: "#58595b",
    colorGreyLight2: "#1D1D1D",
    colorWhite: "#FFFFFF",
    colorBlack: "#000000",
    colorneutral: "#F2F6FF",
    colorGradientPrimary:
      "linear-gradient(143.82deg, #554DDE 4.75%, rgba(85, 77, 222, 0) 61.12%)",
    colorGradientAccent:
      "linear-gradient(132.34deg, #F44E77 -7.22%, rgba(244, 78, 119, 0) 77.9%)",
    colorGradientPrimary2:
      "linear-gradient(47deg, rgba(97,82,162,1) 35%, rgba(73,194,198,1) 61%)",
    colorGradientPrimary3:
      "linear-gradient(36deg, rgba(97,82,162,1) 35%, rgba(73,194,198,1) 100%)",
    colorGray: "#6c757d",
    colorGrey: "#343a40",
    colorFont: "#58595b",
    colorGrey1: "#f8f9fa",
    colorGrey2: "#e9ecef",
    colorGrey3: "#dee2e6",
    colorGrey4: "#ced4da",
    colorGrey5: "#adb5bd",
    colorGrey6: "#6c757d",
    colorGrey7: "#495057",
    colorGrey8: "#343a40",
    colorBorder: "#f8f9fa14",
    colorGrey9: "#D3D3D3",
  },
  {
    name: "light",
    colorPrimary: "#554DDE",
    colorAccent: "#F44E77",
    colorSecondaryDark: "#16194F",
    colorBg: "#191D2B",
    colorBg2: "#10121A",
    colorBg3: "#10121A",
    colorLavender: "#9A94FF",
    colorGreyLight: "#10121A",
    colorGreyLight2: "#10121A",
    colorWhite: "#FFFFFF",
    colorBlack: "#000000",
    colorneutral: "#F2F6FF",
    colorGradientPrimary:
      "linear-gradient(143.82deg, #554DDE 4.75%, rgba(85, 77, 222, 0) 61.12%)",
    colorGradientAccent:
      "linear-gradient(132.34deg, #F44E77 -7.22%, rgba(244, 78, 119, 0) 77.9%)",
    colorGradientPrimary2:
      "linear-gradient(171.21deg, #554DDE -10.74%, #F44E77 112.82%, #F44E77 112.82%)",
    colorGradientPrimary3:
      "linear-gradient(300.81deg, #554DDE -17.99%, #F44E77 111.32%, #F44E77 111.32%)",
    colorGray: "#6c757d",
    colorGrey: "#343a40",
    colorFont: "#a2a2a2",
    colorGrey1: "#f8f9fa",
    colorGrey2: "#e9ecef",
    colorGrey3: "#dee2e6",
    colorGrey4: "#ced4da",
    colorGrey5: "#adb5bd",
    colorGrey6: "#6c757d",
    colorGrey7: "#495057",
    colorGrey8: "#343a40",
    colorBorder: "#f8f9fa14",
    colorGrey9: "#212529b7",
  },
];

export default themes;
