import React from "react";
import styled from "styled-components";
import { useTheme } from "../Context/themeContext";
import ServicesCard from "./ServicesCard";
import ServiceCard from "./ServiceCard";
import Title from "./Title";
import advisoryServ from "../Assets/advisoryServices.svg";
import { productsData } from "../Data/productsData";

const ServicesSection = () => {
  const theme = useTheme();

  return (
    <ServicesSectionStyled
      // className="u-pad-lg-2"
      theme={theme}
      id="services"
    >
      <div className="titleContainer">
        <Title
          name={"Services We Offer"}
          subtext={"For our clients, we provide the greatest services."}
        />
      </div>
      <div className="servicesContainer">
        <ServiceCard
          title={"Advisory & Intermediary Services"}
          desc={
            "We offer the convenience of a personalised one stop shop for all our clients’ insurance requirements. Our bespoke and comprehensive insurance products cover the risk of loss of valuable assets, at the best rates available. We are there during their time of need."
          }
          icon={advisoryServ}
        />
      </div>
      <div className="servicesContainer1">
        {productsData.map((product, index) => {
          return <ServicesCard key={index} product={product} />;
        })}
      </div>
    </ServicesSectionStyled>
  );
};

const ServicesSectionStyled = styled.section`
  margin-left: 18rem;
  margin-right: 18rem;
  margin-bottom: 6rem;

  @media only screen and (max-width: 912px) {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  @media screen and (max-width: 375px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media screen and (max-width: 768px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .servicesContainer {
    /* display: grid;
    grid-template-columns: repeat(3, minmax(320px, 1fr));
    grid-gap: 2rem; */
    padding-top: 3rem;

    /* @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    } */
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      /* padding-left: 10rem; */
      /* align-items: center;
      justify-content: center; */
      /* padding-right: 10rem; */
    }
  }
  .servicesContainer1 {
    display: grid;
    grid-template-columns: repeat(2, minmax(320px, 1fr));
    grid-gap: 2rem;
    padding-top: 3rem;

    /* @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    } */
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      /* padding-left: 10rem; */
      /* align-items: center;
      justify-content: center; */
      /* padding-right: 10rem; */
    }
  }
`;
export default ServicesSection;
