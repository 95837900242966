import personalIns from "../Assets/personalInsuarance.svg";
import commercialIns from "../Assets/commercialInsu.svg";

export const productsData = [
  {
    title: "Personal Insurance",
    desc: "Protect your home from theft, loss and damages with our personal insurance.",
    products: [
      "Household contents",
      "Buildings",
      "All risks",
      "Personal computers",
      "Motor insurance",
      "Personal liability",
      "Personal accident",
    ],
    icon: personalIns,
  },
  {
    title: "Commercial Insurance",
    desc: "We have comprehensive packages to meet business insurance needs :",
    products: [
      "Building and office contents",
      "Business interruption",
      "Goods in transit",
      "Business all risks",
      "Public liability",
      "Motor insurance",
      "Electronic equipment",
    ],
    icon: commercialIns,
  },
];
