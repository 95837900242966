import { useState } from "react";
import styled from "styled-components";
import AboutSection from "./Components/AboutSection";
import FooterSection from "./Components/FooterSection";
import Header from "./Components/Header";
import Hero from "./Components/Hero";
import MobileHeader from "./Components/MobileHeader";
import OurTeam from "./Components/OurTeam";

import ServicesSection from "./Components/ServicesSection";
import WhyUs from "./Components/WhyUs";
import { useTheme } from "./Context/themeContext";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const theme = useTheme();

  return (
    <AppStyled theme={theme}>
      <MobileHeader isOpen={isOpen} toggle={toggle} />
      <Header toggle={toggle} />
      <main>
        <Hero />
        <AboutSection />
        <ServicesSection />
        <WhyUs />
        <OurTeam />
      </main>
      <FooterSection />
    </AppStyled>
  );
}

const AppStyled = styled.div`
  min-height: 100vh;
  position: relative;
  z-index: 5;
  background-color: ${(props) => props.theme.colorBg};
  color: ${(props) => props.theme.colorBlack};
  a {
    color: ${(props) => props.theme.colorBlack};
  }
  p {
    color: ${(props) => props.theme.colorFont};
  }
`;

export default App;
